<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row">
      <div class="col-12">
        <div
          v-if="notifications.length > 0"
          class="card card-custom gutter-b card-stretch"
        >
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                All Notifications
                <span class="text-muted pt-2 font-size-sm d-block"
                  >Lorem ipsun</span
                >
              </h3>
            </div>
          </div>
          <div class="card-body pt-10">
            <div v-if="isNotificationsLoaded" class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-head-bg table-vertical-center mb-0"
                  >
                    <thead>
                      <tr class="bg-gray-100 text-left">
                        <th style="min-width: 250px" class="pl-7">
                          <span>Title</span>
                        </th>
                        <th style="min-width: 150px"><span>Expired Date</span></th>
                        <th style="min-width: 150px"><span>Notification Start Date</span></th>
                        <th style="min-width: 150px"><span>Frequency Type</span></th>
                        <th style="min-width: 170px" class="pr-7 text-right">
                          {{ $t("MENU.ACTIONS") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(notification, key) in notifications"
                        :key="key"
                      >
                        <td
                          class="pl-0 py-4 pl-7"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <div class="d-flex align-items-center">
                            <div>
                              <router-link
                                :to="'/notifications/edit/' + notification.id"
                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                >{{ notification.title }}</router-link
                              >
                              <span class="text-muted font-weight-bold d-block">
                                Description :
                                {{ notification.description }}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span>{{ notification.expiredDate | formatDate }}</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span>{{ notification.notificationStartDate | formatDate }}</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span class="label label-light-primary label-inline">{{ notification.frequencyType }}</span>
                        </td>
                        <td
                          class="pr-7 text-right"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <router-link
                            :to="'/notifications/edit/' + notification.id"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/Design/Edit.svg"
                                title="Edit"
                                aria-label="Edit"
                              />
                            </span>
                          </router-link>
                          <button
                            @click="removeNotification(notification.id)"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/General/Trash.svg"
                              />
                            </span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-md-12 text-center">
                <b-spinner variant="primary" type="grow"></b-spinner>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="alert alert-primary py-4" role="alert">
          Currently no notifications added in the database.
          <router-link
            to="/notifications/new"
            style="
              color: #ffffff;
              float: right;
              font-weight: 600;
              text-decoration: underline;
            "
            >Add Notification</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "ListNotification",
  data() {
    return {
      notifications: [],
      isNotificationsLoaded: false,
      appPath: process.env.VUE_APP_ROUTE_API_URL,
    };
  },
  mounted() {
    ApiService.get("notifications", "?order[createdAt]=desc").then(
      ({ data }) => {
        this.notifications = data["hydra:member"];
        this.isNotificationsLoaded = true;
      }
    );
  },
  methods: { },
};
</script>
